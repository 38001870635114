import React, { useState } from "react";

export default function Form({ children, product, team_id, onStored, dest }) {
	const defaultValues = {
		name: "",
		phone: "",
		comments: "Deseo bajar de 1-5 kg",
		main: "",
	};
	const [inputs, setInputs] = useState(defaultValues);
	const [errors, setErrors] = useState(defaultValues);
	const [loading, setLoading] = useState("");
	const [sent, setSent] = useState(false);

	function handleChange(e, type) {
		const val = e.target.value;
		if (typeof val !== "string") {
			return false;
		}

		setInputs({ ...inputs, [type]: val });
	}

	function handleSubmit(e) {
		e.preventDefault();

		setErrors(defaultValues);

		if (inputs.name.length < 3)
			return setErrors({
				...defaultValues,
				name: "Por favor ingresa tu nombre",
			});
		if (inputs.phone < 100000)
			return setErrors({
				...defaultValues,
				phone: "Por favor ingresa un número de teléfono válido",
			});

		setLoading("cursor-progress bg-navy-300 hover:bg-navy-300");

		// var data = {product: product, team_id: team_id, client_name: inputs.name, client_phone: inputs.phone}
		let data = new FormData();
		data.append("product", product);
		data.append("team_id", team_id);
		data.append("client_name", inputs.name);
		data.append("client_phone", inputs.phone);
		data.append("comments", inputs.comments);
		fetch(dest, {
			method: "POST",
			body: data,
		})
			.then(async (response) => {
				if (!response.ok) {
					const validation = await response.json();
					console.log(validation.errors);
					setErrors({ ...errors, main: validation.errors });
				} else {
					setSent(true);
					window.dataLayer.push({
						event: "lead_stored",
						product: product,
					});
					onStored(e);
				}
				setLoading("");
			})
			.catch((error) => {
				console.log(error);
				setLoading("");
			});
	}

	return (
		<>
			{!sent ? (
				<form onSubmit={handleSubmit}>
					<p className="mt-4 text-gray-600">
						{inputs.name.length ? "Nombre" : ""}
					</p>
					<input
						onChange={(e) => handleChange(e, "name")}
						value={inputs.name}
						className="block w-full px-4 py-3 border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-navy-500 focus:outline-none"
						placeholder="Nombre"
					/>
					{errors.name && (
						<span className="text-red-500 px-2 text-sm">{errors.name}</span>
					)}

					<p className="mt-4 text-gray-600">
						{inputs.phone.length ? "Número de teléfono" : ""}
					</p>
					<input
						onChange={(e) => handleChange(e, "phone")}
						value={inputs.phone}
						type="number"
						className="block w-full appearance-none px-4 py-3 border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-navy-500 focus:outline-none"
						placeholder="Número de teléfono"
					/>
					{errors.phone && (
						<span className="text-red-500 px-2 text-sm">{errors.phone}</span>
					)}

					{/* <p className="mt-4 text-gray-600">¿Cuánto peso deseas bajar?</p>
					<select 
						onChange={(e) => handleChange(e, 'comments')} value={inputs.comments}
						className="block w-full appearance-none px-4 py-3 border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-navy-500 focus:outline-none"
					>
						<option value="Deseo bajar de 1-5 kg">1-5 kilos</option>
						<option value="Deseo bajar de 6-10 kg">6-10 kilos</option>
						<option value="Deseo bajar más de 10 kg">Más de 10 kilos</option>
					</select> */}

					<div className="block">
						{errors.main && (
							<span className="text-red-600 px-2 text-base">{errors.main}</span>
						)}
						<button
							type="submit"
							className={`w-full px-3 py-3 mt-4 font-medium text-white bg-navy-600 hover:bg-navy-500 rounded-lg ${loading} `}
						>
							{loading ? "Enviando..." : "Continuar"}
						</button>
					</div>
				</form>
			) : (
				<div className="flex flex-col items-center justify-center">
					<h2 className="text-2xl font-bold text-gray-800">¡Gracias!</h2>
					<p className="text-gray-600">
						En breve nos pondremos en contacto contigo.
					</p>
				</div>
			)}
		</>
	);
}
