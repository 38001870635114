import React, { useState } from 'react'
import { Tab } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CustomTabs({sections}) {
  let [categories] = useState(sections)

  return (
    <div className="w-full mx-auto max-w-md px-2 py-16 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-navy-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-navy-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-gold-200 shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((section, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-xl p-3',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60'
              )}
            >
				<h3 className='font-semibold text-2xl text-navy-600 text-center pb-6 uppercase'>
              		{section.title}
                </h3>
				<p className='text-center' dangerouslySetInnerHTML={{ __html: section.description}} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
