import * as React from "react";
import Layout from "../layouts/layout";

import mainImage from "../images/main.jpeg";
import mainMobileImage from "../images/main-mobile.jpeg";
import testimonialOne from "../images/vane.jpeg";
import testimonialTwo from "../images/carlos.jpeg";
import CTAImage from "../images/cta.png";
import CTAMobileImage from "../images/cta-mobile.png";
import Modal from "../components/Modal";
import Tabs from "../components/Tabs";
import Form from "../components/Form";

// markup
const Campaign = () => {
	const modalRef = React.useRef();

	return (
		<div>
			<Layout title="CLA Keto Formula Quema Grasa Rápido Sin Dietas Ni Ejercicio">
				<section className="px-2 pt-24 bg-white md:px-0">
					<div className="container items-center px-6 mx-auto xl:px-3">
						<div className="flex justify-center mt-6 sm:mt-0">
							<img
								src={mainImage}
								className="hidden md:block w-3/4 h-fit"
								alt="CLA Keto Formula"
							/>
							<img
								src={mainMobileImage}
								className="block md:hidden mt-6"
								alt="CLA Keto Formula"
							/>
						</div>
					</div>
					<div className="w-full h-fit bg-navy-400 py-3 text-center">
						<h5 className="uppercase text-yellow-300 font-semibold text-xl">
							Oferta exclusiva para Costa Rica
						</h5>
						<h5 className="uppercase text-white font-semibold text-lg">
							Información directa: 4001-8798
						</h5>
						<button
							type="button"
							className="text-center w-60 px-6 py-3 my-3 text-xl uppercase font-semibold text-white bg-gold-500 rounded-md sm:mb-0 hover:bg-gold-400 transition-colors"
							onClick={() => modalRef.current.openModal()}
						>
							Ordenar ahora
						</button>
					</div>
				</section>

				<section className="pt-14 mb-12">
					<div className="items-center max-w-3xl px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
						<h2 className="text-navy-500 uppercase text-center font-bold text-3xl mb-4">
							¿Cómo funciona Carbotrap CLA Keto Formula?
						</h2>
						<p className="text-center">
							La ketosis es un proceso metabólico natural de tu cuerpo que te
							ayuda a quemar grasa eficientemente,{" "}
							<span className="underline decoration-2 decoration-double decoration-navy-500 underline-offset-4">
								transformándola en energía
							</span>{" "}
							para lograr un mejor rendimiento físico y mental mientras pierdes
							peso rápidamente.
						</p>

						<h2 className="text-gold-500 uppercase text-center font-bold text-3xl mb-4 mt-12">
							Qué nos diferencia del resto?
						</h2>
						<p className="text-center">
							Incluimos <span className="font-semibold">CLA</span> en nuestra
							fórmula, el cual es un potente quemador de grasa y un proveedor de
							Omega 3, 6 y 9, limpiando tu organismo de colesterol malo y además
							ayuda a controlar los trigliceridos.
						</p>
					</div>
				</section>

				<section className="py-20 lg:py-24 bg-gold-400/80">
					<div className="max-w-7xl mx-auto">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-x-24 gap-y-6">
							<div>
								<h4 className="font-bold text-3xl uppercase text-center">
									Cómo utilizar{" "}
									<span className="text-navy-500">CLA Keto Formula</span> para
									quemar grasa
								</h4>
								<Tabs
									sections={{
										"Paso 1": {
											title: "Tomar CLA Keto Formula diariamente",
											description:
												"<b>Carbotrap CLA Keto Formula</b> te ayudará a entrar en un estado de ketosis el cual es clave para ayudarte a quemar grasa y utilizarla como energía. Es necesario tomar una cápsula 20 minutos antes de cada comida (3 veces al día) sin interrupciones.",
										},
										"Paso 2": {
											title:
												"Estado de Ketosis activado. Pierde grasa aceleradamente",
											description:
												"Con la ketosis activada, <b>Carbotrap CLA Keto Formula</b> te ayudará a liberar grasa almacenada innecesaria de manera acelerada, convirtiéndola en energía que tu cuerpo empezará a aprovechar, además bloqueando los carbohidratos y limpiando el colesterol malo del cuerpo.",
										},
										"Paso 3": {
											title: "Transforma tu cuerpo y mantén tu estado ideal",
											description:
												"Cuando hayas alcanzado tus objetivos, continúa tomando <b>Carbotrap CLA Keto Formula</b> durante 3-5 meses para mantener los resultados obtenidos y evitar un efecto rebote, manteniendo tu cuerpo delgado y sano.",
										},
									}}
								/>
							</div>
							<div>
								<h1 className="font-bold text-3xl text-navy-500 uppercase text-center">
									Activa la ketosis en tu cuerpo
								</h1>
								<h3 className="text-orange-500 uppercase font-bold text-2xl text-center my-5">
									Utiliza grasa como energía <br /> potencia tu metabolismo
								</h3>
								<p className="leading-relaxed text-center md:text-left px-4">
									Cuando tu cuerpo realiza la ketosis, la grasa también se
									convierte en cetonas en el hígado, que puede suministrar
									energía para tu cerebro. Carbotrap CLA Keto Formula te ayuda a
									alcanzar el proceso de ketosis fácilmente, superando los
									efectos negativos inmersos en una dieta ketogénica.
									<br />
									<br />
									La ketosis es muy difícil de alcanzar de manera natural. Por
									eso, Carbotrap CLA Keto Formula te ayudará a entrar
									rápidamente en este proceso, logrando a su vez un estado
									activo y rápido de pérdida de peso.
									<br />
									<br />
									¡Alcanza tus objetivos y ama la forma en la que te sientes!
								</p>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div className="bg-[url('./images/ketobg.jpg')] bg-cover bg-center w-full h-fit">
						<div className="flex flex-col pl-4 sm:pl-12 md:pl-8 lg:pl-24 xl:pl-40 py-28">
							<div className="text-white font-semibold text-center w-56 md:w-96 lg:w-1/2">
								<h2 className="text-3xl lg:text-4xl uppercase">
									Todos los beneficios de la ketosis
								</h2>
								<h3 className="text-2xl lg:text-3xl pt-5">
									¡Sin dietas ni ejercicio!
								</h3>
							</div>
						</div>
					</div>
				</section>

				<section className="max-w-6xl mx-auto pt-12">
					<h1 className="text-4xl font-bold uppercase text-center">
						<span className="text-gold-500">Beneficios</span> de{" "}
						<span className="text-navy-500">CLA Keto Formula</span>
					</h1>
					<div className="grid grid-cols-1 md:grid-cols-2 justify-center justify-items-center mt-10 gap-x-8 gap-y-10 mx-4">
						<div className="hidden md:block"></div>
						<div className="h-fit shadow-md shadow-gray-600/50 justify-self-center">
							<div className="py-3 bg-blue-800 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Estimula la pérdida de peso
								</h3>
							</div>
							<p className="p-4">
								Carbotrap CLA Keto Formula ayuda a perder peso, quemar grasa en
								tu organismo, transformándola en energía eficientemente para que
								tu cuerpo pueda obtener energía de grasa en lugar de
								carbohidratos, lo que puede acelerar el proceso de pérdida de
								peso. Ya que la ketosis es muy difícil de lograr y tarda un par
								de semanas, Carbotrap CLA Keto Formula te ayuda a alcanzar el
								estado de ketosis más rápido y de forma segura, logrando así
								quemar grasa y perder peso en el proceso.
							</p>
						</div>
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 bg-emerald-400 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Utiliza grasa como energía
								</h3>
							</div>
							<p className="p-4">
								El objetivo principal de un proceso de ketosis es utilizar la
								grasa como combustible y producir energía, pero también ayudar a
								controlar el apetito, reducir la ansiedad y los antojos de
								azúcar.
							</p>
						</div>
						<div className="hidden md:block"></div>
						<div className="hidden md:block"></div>
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 bg-yellow-400/90 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Más beneficios para la salud
								</h3>
							</div>
							<p className="p-4">
								Carbotrap CLA Keto Formula también puede ayudarte a disminuir el
								riesgo de enfermedades cardiovasculares, mejorar la calidad de
								la piel y reducir el acné, regular la presión arterial, mejorar
								el rendimiento atlético y cerebral, mejorando así la memoria, la
								concentración y reduciendo el estrés.
							</p>
						</div>
					</div>
					<div className="flex justify-center mt-16 mx-4">
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 bg-rose-500 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Alcanza tus objetivos con la ketosis
								</h3>
							</div>
							<p className="p-4">
								Carbotrap CLA Keto Formula te ayuda a alcanzar el proceso de
								ketosis rápidamente, logrando todos los beneficios que ya
								conociste.
								<br />
								<span className="font-semibold">
									¡Dale a tu cuerpo energía y salud!
								</span>
							</p>
						</div>
					</div>
				</section>

				<section className="bg-gray-100 pt-20 pb-24 mt-12">
					<div className="max-w-4xl mx-auto">
						<div className="flex flex-col justify-center gap-8 mx-4 mb-8">
							<div className="grid grid-cols-1 md:grid-cols-2 bg-white">
								<div className="bg-[url('./images/vane.jpeg')] bg-center bg-cover bg-no-repeat hidden md:block"></div>
								<div className="block md:hidden">
									<img src={testimonialOne} alt="Vane" className="" />
								</div>
								<div className="py-5 pl-2">
									<div className="flex flex-col pr-8">
										<div className="relative pl-12">
											<svg
												className="absolute left-0 w-10 h-10 text-navy-500 fill-current"
												data-primary="indigo-500"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 100 125"
												data-primary-reset="{}"
											>
												<path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
											</svg>
											<p className="mt-2 text-gray-600 text-lg">
												Probé decenas de dietas que me recomendaban y productos
												para quemar grasa. Nada me funcionaba, me sentía
												estancada. Pero cuando conocí Carbotrap CLA Keto Formula
												comencé a ver grandes resultados en poco tiempo. Pasé de
												pesar 73 kilos a 65 y estoy segura que sin este producto
												no lo hubiera logrado. Lo recomiendo bastante.
											</p>
										</div>

										<h3 className="pl-12 mt-3 font-medium leading-5 text-gray-800 text-lg">
											Vanessa M.
										</h3>
									</div>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-2 bg-white">
								<div className="bg-[url('./images/carlos.jpeg')] bg-center bg-cover bg-no-repeat hidden md:block"></div>
								<div className="block md:hidden">
									<img src={testimonialTwo} alt="Carlos" className="" />
								</div>
								<div className="py-5 pl-2">
									<div className="flex flex-col pr-8">
										<div className="relative pl-12">
											<svg
												className="absolute left-0 w-10 h-10 text-navy-500 fill-current"
												data-primary="indigo-500"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 100 125"
												data-primary-reset="{}"
											>
												<path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
											</svg>
											<p className="mt-2 text-gray-600 text-lg">
												No acostumbraba a hacer ejecicio ni tenía una dieta
												saludable. Mi estado físico me acomplejaba. Un familiar
												me recomendó Carbotrap CLA Keto Formula ya que había
												tenido buenos resultados. Llevo casi un año tomándolo y
												el cambio que ha dado mi vida habla por sí solo. Perdía
												bastante peso de grasa acumulada, y tuve la suficiente
												energía y motivación para realizar ejercicio, ¡Me parece
												increíble como estoy ahora! Este producto funciona,
												funciona muy bien.
											</p>
										</div>

										<h3 className="pl-12 mt-3 font-medium leading-5 text-gray-800 text-lg">
											Carlos N.
										</h3>
									</div>
								</div>
							</div>
						</div>

						<h2 className="font-semibold text-4xl uppercase text-center mx-4">
							¡Al igual que ellos,{" "}
							<span className="text-gold-500">tú también puedes lograrlo</span>!
						</h2>
					</div>
				</section>

				<section className="pt-20 pb-6 max-w-6xl mx-auto">
					<div className="relative w-fit">
						<img src={CTAImage} className="hidden md:block" alt="" />
						<img src={CTAMobileImage} className="block md:hidden" alt="" />
						<div className="absolute bottom-[15%] left-[50%] -translate-x-1/2 md:translate-x-0 md:bottom-60 md:left-52 lg:left-72 text-white">
							<button
								type="button"
								className="text-center w-48 sm:w-60 px-6 py-3 my-3 text-base sm:text-xl uppercase font-semibold text-white bg-gold-500 rounded-md sm:mb-0 hover:bg-gold-400 transition-colors"
								onClick={() => modalRef.current.openModal()}
							>
								Ordenar ahora
							</button>
						</div>
					</div>
				</section>

				<section className="pt-20 pb-12 max-w-6xl mx-auto px-3">
					<p className="text-center text-sm">
						Todos nuestros productos se fabrican en los Estados Unidos en
						instalaciones registradas, cumpliendo siempre con las Buenas
						Prácticas de Fabricación GMP (Good Manufacturing Practice).
						Prestamos atención en todos los pequeños detalles, proporcionando a
						nuestros clientes la mejor calidad con componentes 100% naturales y
						sin aditivos ni conservantes artificiales. Todas nuestras fórmulas
						han sido probadas y verificadas en laboratorios legales y de
						confianza para asegurar que obtendrás los mejores resultados.
						<br />
						<br />
						Aviso: Los productos y la información que se encuentran en este
						sitio no están destinados a reemplazar el tratamiento o consejo
						médico profesional. Estas declaraciones no han sido evaluadas por la
						Food and Drug Administration (FDA). Estos productos no están
						destinados a diagnosticar, tratar, curar o prevenir ninguna
						enfermedad. Los resultados individuales pueden variar.
					</p>
				</section>

				<button
					className="fixed bottom-0 w-full h-12 bg-navy-500 hover:bg-navy-600 cursor-pointer transition-colors flex items-center justify-center text-white font-semibold text-lg uppercase"
					onClick={() => modalRef.current.openModal()}
				>
					<p>¡Ordena aquí ahora!</p>
				</button>
			</Layout>
			<Modal ref={modalRef}>
				<div className="flex flex-col items-center md:flex-row">
					<div className="w-full space-y-5 md:w-3/5 md:pr-16">
						<p className="font-medium text-navy-500 uppercase">
							CLA Keto Formula
						</p>
						<h2 className="text-xl sm:text-2xl md:text-4xl font-extrabold leading-none text-navy-600">
							Comienza a perder peso{" "}
							<span className="text-gold-500">quemando grasa</span> en una
							manera rápida y segura.
						</h2>
						<p className="text-xl text-gray-600 md:pr-16">
							Baja de peso rápidamente con la poderosa fórmula de{" "}
							<span className="font-semibold">CLA Keto</span> y comienza a ver
							resultados en el primer mes.
						</p>
						<p className="text-gray-800 text-lg">
							Ordena ahora y recibe <span className="font-black">GRATIS</span>{" "}
							la{" "}
							<span className="text-navy-500 font-semibold underline decoration-sky-500">
								guía nutricional para acelerar tu proceso de pérdida de peso
							</span>{" "}
							con recetas deliciosas y saludables, pensadas por nuestro equipo
							de nutricionistas para combinar con
							<span className="font-semibold"> CLA Keto Formula</span> y obtener
							los mejores resultados.
						</p>
					</div>

					<div className="w-full mt-8 md:mt-0 md:w-2/5">
						<div
							className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-lg px-7"
							data-rounded="rounded-lg"
							data-rounded-max="rounded-full"
						>
							<h3 className="mb-6 text-2xl font-medium text-center">
								Déjanos tus datos
							</h3>
							<Form
								dest="https://crm.almalunatural.com/api/campaigns/store-lead"
								product="CLA Keto Formula"
								team_id="1"
								onStored={() => modalRef.current.successForm()}
							/>
							{/* <p className="w-full mt-4 text-sm text-center text-gray-500">¿Dudas? <a target="_blank" href="https://wa.me/17879173091?text=Quiero recibir información sobre CLA Keto Formula y bajar de peso rápidamente" className="text-navy-500 hover:text-navy-600 underline">Escríbenos por WhatsApp</a></p> */}
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default Campaign;
